import React from "react";
import { Link } from "react-router-dom";

interface NavbarProps {
  brand: string;
  links: { label: string; href: string }[];
}

const Navbar = ({ brand, links }: NavbarProps) => {
  return (
    <nav className="bg-primary-700 text-white py-2 px-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold">{brand}</div>
        <div className="space-x-4">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className="hover:bg-primary-900 px-3 py-2 rounded"
            >
              {link.label}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
