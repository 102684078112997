import React from 'react';

import './App.css';
import Button from './components-ui/Button';
import Accordion from './components-ui/Accordian';
import Alert from './components-ui/Alert';
import Badge from './components-ui/Badge';
import Breadcrumb from './components-ui/Breadcrum';
import Carousel from './components-ui/Carousel';
import Navbar from './components-ui/Navbar';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Routes,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="url1" element={<Navbar brand='fddfdf' links={[{href:'home', label: 'home'}, {href:'cars', label: 'cars'}]} />} />
      <Route path="url2" element={<Carousel images={['https://picsum.photos/200/300', 'https://picsum.photos/200/200', 'https://picsum.photos/200/400']}/>}/>

      </Routes>
      
      <Accordion title='some tile'>sometext</Accordion>
      <Alert type='error' >some text</Alert>
      <Badge>some text</Badge>
      <Breadcrumb items={[{href:'home', label: 'home'}, {href:'cars', label: 'cars'}]}></Breadcrumb>
      <Button onClick={()=>window.prompt("Hiii")}>Some Text Inside Button</Button>
      
    </div>
  );
}

export default App;
